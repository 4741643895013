<template>
  <v-container fluid>
    <v-alert dismissible type="error" v-if="errorMsg != ''">{{
      errorMsg
    }}</v-alert>
    <v-alert border="left" type="warning" elevation="2" v-if="info.length != 0">
      {{ info }}
    </v-alert>
    <v-row>
      <v-col lg="4">
        <v-card elevation="10" class="mx-auto">
          <v-app-bar color="blue" flat dark>
            <v-toolbar-title>
              <v-icon>mdi-account-details</v-icon>
              กรอกรายละเอียด
            </v-toolbar-title>
          </v-app-bar>
          <v-card-title>
            <!-- <v-list-item class="grow">
            <v-list-item-avatar color="grey darken-3">
              <v-img
                class="elevation-6"
                alt=""
                :src="lineProfilePicture"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ lineDisplayName }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          </v-card-title>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                maxlength="10"
                hint="รูปแบบการกรอกข้อมูล (0861234567)"
                label="เบอร์โทรศัพท์"
                required
              >
                <!-- <template v-slot:append-outer>
                  <v-btn @click="getAgentDetail">ดึงข้อมูล</v-btn>
                </template> -->
              </v-text-field>

              <v-text-field
                v-model="name"
                :counter="100"
                :rules="nameRules"
                label="ชื่อ-นามสกุล/ชื่อนิติบุคคล"
                required
              ></v-text-field>

              <v-text-field
                v-model="cid"
                :counter="13"
                :rules="cidRules"
                maxlength="13"
                label="เลขประจำตัวประชาชน/เลขที่นิติบุคคล"
                hint="รูปแบบการกรอกข้อมูล (1509921234231)"
                required
              ></v-text-field>

              <v-text-field
                v-model="address"
                :counter="250"
                :rules="addressRules"
                maxlength="250"
                label="ที่อยู่"
                hint="ex. (110 หมู่ 2 ต.บางปะอิน อ.บางปะอิน จ.อยุธยา)"
                required
              ></v-text-field>

              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
              ></v-text-field>

              <!-- <v-checkbox
                label="เข้าลงอัตลักษณ์ในวันอาทิตย์ ที่ 4 เม.ย. (09.00-12.00น.)"
                v-model="onSunday3"
              ></v-checkbox> -->

              <template v-if="$moment().isBetween('2021-03-21', '2021-03-27')">
                <v-checkbox
                  label="เข้าลงอัตลักษณ์ในช่วงเวลา (16.00-18.00น.)"
                  v-model="onExtraTime"
                ></v-checkbox>

                <!-- date -->

                <v-menu
                  v-model="datepicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="เลือกวันที่"
                      v-model="queueDate"
                      v-bind="attrs"
                      v-on="on"
                      v-show="onExtraTime"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="queueDate"
                    min="2021-03-23"
                    max="2021-03-26"
                    @input="datepicker = false"
                  ></v-date-picker>
                </v-menu>
              </template>

              <!-- Can Choose Date -->
              <!-- date -->

              <!-- <v-menu
                v-model="datepicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="เลือกวันที่"
                    v-model="queueDate"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="queueDate"
                  :allowed-dates="getAllowedDates"
                  min="2021-03-30"
                  max="2021-04-16"
                  @input="datepicker = false"
                ></v-date-picker>
              </v-menu>
              <v-select
                v-model="queueTime"
                :items="timeRanges"
                label="เลือกช่วงเวลา"
                :rules="[v => !!v || 'กรุณาเลือกช่วงเวลาที่จะมา']"
                required
              >
              </v-select> -->
            </v-form>
          </v-container>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="mx-auto">
          <v-app-bar color="blue" flat dark>
            <v-toolbar-title>
              <v-icon>mdi-account-multiple</v-icon>
              รายชื่อแรงงานต่างด้าว
            </v-toolbar-title>
            <v-spacer />
            <v-row justify="end">
              <v-icon>mdi-account</v-icon> {{ foreignworker }}
            </v-row>
          </v-app-bar>
          <v-container>
            <v-list two-line>
              <v-list-item>
                <v-list-item-content> </v-list-item-content>
                <v-row align="center" justify="end">
                  <v-btn
                    @click="() => (workerDialog = true)"
                    :disabled="workerList.length == 40"
                  >
                    <v-icon class="mr-1"> mdi-plus </v-icon>
                    เพิมรายชื่อ
                  </v-btn>
                </v-row>
              </v-list-item>
              <v-divider></v-divider>
              <template v-for="(item, index) in workerList">
                <v-list-item :key="item.rid">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.refId }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-row align="center" justify="end">
                    <v-btn @click="editWorker(index)" icon>
                      <v-icon> mdi-pencil </v-icon>
                    </v-btn>
                    <v-btn @click="removeWorker(index)" icon>
                      <v-icon> mdi-account-remove </v-icon>
                    </v-btn>
                  </v-row>
                </v-list-item>
                <v-divider :key="index"></v-divider>
              </template>
            </v-list>
          </v-container>
          <v-card-actions>
            <small class="red--text">
              ตรวจสอบข้อมูลให้แน่ใจว่าถูกต้องครบถ้วนก่อนกดบันทึกข้อมูล
            </small>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!valid"
              color="success"
              class="mr-4"
              :loading="loading"
              @click="saveData"
              x-large
            >
              <v-icon>mdi-content-save</v-icon>
              บันทึกข้อมูล
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <form-modal
        v-model="workerDetail"
        :dialog="workerDialog"
        @closeDialog="closeDialog"
        @save="addWorkerList"
      />
    </v-row>
  </v-container>
</template>

<script>
import formModal from "./components/formModal";
import { getSetting } from "@/utils/app.setting";
import { getToken, reserve } from "./http/req";
export default {
  components: { formModal },
  data() {
    return {
      valid: false,
      datepicker: false,
      name: "",
      nameRules: [v => !!v || "จำเป็นต้องกรอกข้อมูล"],
      cid: "",
      cidRules: [
        v => !!v || "จำเป็นต้องกรอกข้อมูล",
        v => /\d{13}/.test(v) || "ตัวเลขเท่านั้นและต้องมี 13 หลัก"
      ],
      phone: "",
      phoneRules: [
        v => !!v || "จำเป็นต้องกรอกข้อมูล",
        v => /\d{10}/.test(v) || "เฉพาะตัวเลขไม่ต้องเว้นวรรคหรือใส่เครื่องหมาย"
      ],
      email: "",
      emailRules: [
        // v => !!v || "E-mail is required",
        v => (v && /.+@.+\..+/.test(v)) || "กรอก Email ให้ถูกต้อง"
      ],
      address: "",
      addressRules: [v => !!v || "จำเป็นต้องกรอกข้อมูล"],
      foreignworker: "",
      checkbox: false,
      lineUserId: "",
      lineProfilePicture: "",
      lineDisplayName: "",
      workerDialog: false,
      workerIsUpdate: false,
      onSunday27: false,
      onSunday3: false,
      onExtraTime: false,
      allowedDates: [
        "2021-03-30",
        "2021-03-31",
        "2021-04-01",
        "2021-04-02",
        "2021-04-04",
        "2021-04-05",
        "2021-04-06",
        "2021-04-07",
        "2021-04-08",
        "2021-04-09",
        "2021-04-11",
        "2021-04-12",
        "2021-04-13",
        "2021-04-14",
        "2021-04-15",
        "2021-04-16"
      ],
      timeRanges: [
        { value: "09:00:00", text: "09.00-12:00" },
        { value: "13:00:00", text: "13.00-16:00" },
        { value: "16:00:00", text: "16.00-18:00" }
      ],
      queueDate: this.$moment()
        .add(1, "d")
        .format("YYYY-MM-DD"),
      queueTime: "09:00:00",
      updateIndex: -1,
      workerDetail: {
        name: "",
        refId: "",
        gender: "",
        national: ""
      },
      workerList: [],
      dataSaved: false,
      errorMsg: "",
      token: "",
      loading: false,
      info: "",
      updateAgent: false,
      agentId: null
    };
  },
  created() {
    // // this.lineUserId = this.$route.params.lineUserId;
    // // this.lineProfilePicture = this.$route.params.lineProfilePicture;
    // // this.lineDisplayName = this.$route.params.lineDisplayName;
    // this.phone = this.$route.params.phone;
    // this.token = this.$route.params.token;
    this.getNotice();
    // this.getAgentDetail();
  },
  watch: {
    workerList(v) {
      this.foreignworker = v.length;
      if (v.length > 0) {
        this.errorMsg = "";
        this.valid = true;
        this.$refs.form.validate();
      }
    },
    phone(v) {
      if (!v) return;
      if (v.length == 10 && /\d{10}/.test(v)) {
        this.getAgentDetail();
      }
    },
    onSunday27(v) {
      if (v) {
        this.onExtraTime = false;
        this.onSunday3 = false;
      }
    },
    onSunday3(v) {
      if (v) {
        this.onSunday27 = false;
        this.onExtraTime = false;
      }
    },
    onExtraTime(v) {
      if (v) {
        this.onSunday27 = false;
        this.onSunday3 = false;
      }
    },
    queueDate(v) {
      const d = [
        "2021-04-04",
        "2021-04-06",
        "2021-04-11",
        "2021-04-12",
        "2021-04-13",
        "2021-04-14",
        "2021-04-15"
      ];
      if (d.includes(v)) {
        this.timeRanges = [{ value: "09:00:00", text: "09.00-12:00" }];
        this.queueTime = "09:00:00";
      } else {
        this.timeRanges = [
          { value: "09:00:00", text: "09.00-12:00" },
          { value: "13:00:00", text: "13.00-16:00" },
          { value: "16:00:00", text: "16.00-18:00" }
        ];
      }
    }
  },
  methods: {
    // new method
    async saveData() {
      if (this.$refs.form.validate()) {
        if (this.workerList.length == 0) {
          this.errorMsg = "กรุณากรอกข้อมูลแรงงาน";
          this.valid = false;
          return;
        }

        this.loading = true;
        var tk = await getToken();
        var data = {
          workers: this.workerList,
          name: this.name,
          email: this.email,
          cid: this.cid,
          address: this.address,
          phone: this.phone,
          agentId: this.agentId,
          updateAgent: this.updateAgent,
          onSunday3: this.onSunday3,
          queueDate: this.queueDate,
          queueTime: this.queueTime
        };
        if (tk != "") {
          reserve(tk, data)
            .then(res => {
              this.dataSaved = true;
              // console.log(res.data);
              this.$router.push({
                path: "/queue_result",
                query: { id: res.data.queueId }
              });
            })
            .catch(err => {
              console.log(err);
              this.errorMsg = "Invalid OTP";
            })
            .finally(() => (this.loading = false));
        }
      }
    },
    // allow
    getAllowedDates(v) {
      return this.allowedDates.includes(v);
    },
    // old method
    validate() {
      if (this.$refs.form.validate()) {
        if (this.workerList.length == 0) {
          this.errorMsg = "กรุณากรอกข้อมูลแรงงาน";
          this.valid = false;
          return;
        }

        const url = process.env.VUE_APP_BASE_URL + "/api/queue_reservation";
        var config = {
          method: "post",
          url: url,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`
          },
          data: {
            workers: this.workerList,
            name: this.name,
            email: this.email,
            cid: this.cid,
            address: this.address
          }
        };
        this.loading = true;
        this.axios(config)
          .then(res => {
            // console.log(res.data);
            this.$router.push({
              path: "/queue_result",
              query: { id: res.data.queueId }
            });
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => (this.loading = false));
        this.dataSaved = true;
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    addWorkerList() {
      if (this.workerIsUpdate && this.updateIndex != -1) {
        this.updateWorker();
        return;
      }
      this.workerList.push(Object.assign({}, this.workerDetail));
      this.clearWorkerDetail();
      this.workerDialog = false;
    },
    updateWorker() {
      Object.assign(this.workerList[this.updateIndex], this.workerDetail);
      this.clearWorkerDetail();
      this.workerDialog = false;
      this.updateIndex = -1;
      this.workerIsUpdate = false;
    },
    removeWorker(index) {
      this.workerList.splice(index, 1);
    },
    editWorker(index) {
      this.updateIndex = index;
      Object.assign(this.workerDetail, this.workerList[index]);
      this.workerIsUpdate = true;
      this.workerDialog = true;
    },
    clearWorkerDetail() {
      this.workerDetail = {
        name: "",
        refId: "",
        gender: "",
        national: ""
      };
    },
    closeDialog() {
      this.workerDialog = false;
      this.clearWorkerDetail();
    },
    preventNav(event) {
      if (this.dataSaved) return;
      event.preventDefault();
      event.returnValue = "";
    },
    async getNotice() {
      let data = await getSetting("notice_register_page");
      this.info = data;
    },
    // ดึงข้อมูล agent
    async getAgentDetail() {
      if (this.phone === "" || this.phone === undefined || this.phone === null)
        return;

      let url = process.env.VUE_APP_BASE_URL + `/api/get_agent/${this.phone}`;
      let tk = await getToken();
      const config = {
        url: url,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${tk}`
        }
      };
      this.axios(config)
        .then(res => {
          // console.log(res.data);
          // console.log(Object.keys(res.data));
          // console.log(res.constructor);
          if (
            res.data &&
            Object.keys(res.data).length != 0 &&
            res.data.constructor === Object
          ) {
            let { name, address, cid, email, id } = res.data;
            this.name = name;
            this.address = address;
            this.cid = cid;
            this.email = email;
            this.updateAgent = true;
            this.agentId = id;
          } else {
            this.agentId = null;
            this.updateAgent = false;
          }
        })
        .catch(err => {
          this.agentId = null;
          this.updateAgent = false;
        });
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    });
  },

  beforeRouteLeave(to, from, next) {
    if (!this.dataSaved) {
      if (!window.confirm("Leave without saving?")) {
        return;
      }
    }
    next();
  }
};
</script>

<style></style>
