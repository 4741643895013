import axios from "axios";

export async function getToken() {
  const formData = new FormData();
  formData.append("grant_type", "client_credentials");
  formData.append("client_secret", "dN9XEgT0F7KzsuIpsdvXOXxINXpOvxLNIpGgDziv");
  formData.append("client_id", "4");
  var config = {
    method: "post",
    url: process.env.VUE_APP_BASE_URL + "/oauth/token",
    headers: {
      Accept: "application/json",
      "Content-type": "multipart/form-data"
    },
    data: formData
  };
  var res = await axios(config);
  // console.log(res);
  return res.data.access_token ?? "";
}

export function reserve(token, data) {
  var config = {
    method: "post",
    url: process.env.VUE_APP_BASE_URL + "/api/agentReserve",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`
    },
    data: data
  };

  return new Promise((resolve, reject) => {
    // Do something here... lets say, a http call using vue-resource
    axios(config).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      }
    );
  });
}

export function reqOtp(token, data) {
  var config = {
    method: "post",
    url: process.env.VUE_APP_BASE_URL + "/api/req_otp",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`
    },
    data: data
  };
  return new Promise((resolve, reject) => {
    // Do something here... lets say, a http call using vue-resource
    axios(config).then(
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      }
    );
  });
}
